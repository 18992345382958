// Helper functions for Jobs collection
import { GeoPoint } from 'firelordjs';
import { distanceBetween } from 'geofire-common';

import {
  compact,
  find,
  get,
  map,
  orderBy,
  some,
  startCase,
  unionBy,
} from 'lodash';

import { BrokerCompaniesRecord } from '@/lib/context/SchedulerContext';

import { firestore } from '@/lib/firebase';
import {
  JobCompanyTruckData,
  JobDispatchStage,
  JobOverallStatus,
  ProductMaterial,
} from '@/lib/firebase/db/@types';
import { Companies } from '@/lib/firebase/db/collections';
import { getIsTaskInvoiced } from '@/lib/firebase/db/helpers';
import { CompanyDoc, JobDoc } from '@/lib/firebase/db/metaTypes';
import { tasksByJobQuery } from '@/lib/firebase/db/queries';

/**
 * Retrieves the start datetime for a specific group within a job.
 * @function
 * @param {JobDoc} jobDoc - The job document.
 * @param {number} groupIndex - The index of the group within the job.
 * @returns {Date} The start datetime for the specified group.
 */
export function getJobStartDatetime(jobDoc: JobDoc, groupIndex: number = 0) {
  const jobStartDatetime = jobDoc.data()?.startDatetime.toDate() || new Date();
  const groupData = jobDoc.data()?.groupsOfOperators[groupIndex];
  if (!groupData) return jobStartDatetime;
  const groupTimeOffsetMs = groupData.startDatetimeDifferenceMs || 0;
  return new Date(+jobStartDatetime + groupTimeOffsetMs);
}

export function getJobProductLabel(jobDoc: JobDoc) {
  const jobData = jobDoc.data();
  if (!jobData?.productCode) return '';
  return [jobData.productCode, jobData.productName].join(' - ');
}

export function getJobProductName(jobDoc: JobDoc) {
  const productName = jobDoc.get('productName') || '';
  const productMaterial = jobDoc.get('productMaterial');
  return !!productMaterial && productName === productMaterial
    ? getProductMaterialName(productMaterial)
    : productName;
}

export function getProductMaterialName(
  productMaterial: ProductMaterial
): string {
  const materialName: Record<ProductMaterial, string> = {
    aggregates: 'Aggregates',
    asphalt: 'Asphalt',
    cementitious: 'Cementitious',
    dirt: 'Dirt',
    other: 'Other',
  };
  return materialName[productMaterial];
}

/**
 * Generates a string representing the product details of a job.
 *
 * @param {JobDoc} jobDoc - The job object containing product details.
 * @returns {string} A string in the format "Name - Code", depending on the available fields.
 *
 * @example
 * // Example 1: Job object with products
 * getJobProductStr({ material: 'asphalt', products: { code: 'P123', name: 'Hot Mix' } });
 * // Returns: 'Hot Mix - P123'
 *
 * @example
 * // Example 2: Job object with productCode and productName
 * getJobProductStr({ material: 'aggregates', productCode: 'A456', productName: '57 Stone' });
 * // Returns: '57 Stone - A456'
 *
 * @example
 * // Example 3: Job object with code, name and material
 * getJobProductStr({ material: 'dirt', code: 'D789', name: 'Fill Dirt' });
 * // Returns: 'Fill Dirt - D789'
 *
 * @example
 * // Example 4: Job object where code, name and material are the same (used when there are no SiteProducts in the JobForm)
 * getJobProductStr({ material: 'Asphalt', code: 'Asphalt', name: 'Asphalt' });
 * // Returns: 'Asphalt'
 */
export function getJobProductStr(jobDoc: JobDoc): string {
  const material = jobDoc.get('productMaterial');
  const code = jobDoc.get('productCode');
  const name = jobDoc.get('productName');

  if (!!code && !!name && !!material) {
    return code === name
      ? getProductMaterialName(material)
      : `${name} - ${code}`;
  }
  return '';
}

/**
 * Filters a list of job documents by their location, returning only those within a specified distance from a central point.
 * The function finds the initial site (order: 0) for each job and calculates the distance to the center point. Only jobs where
 * the initial site is within the specified distance from the center point are included in the result.
 *
 * @param {JobDoc[]} jobs - An array of job documents to filter.
 * @param {GeoPoint} center - The central point to measure distance from, with `lat` and `lng` properties.
 * @param {number} distance - The maximum distance (in meters) from the center point for a job to be included in the result.
 * @returns {JobDoc[]} An array of job documents that are within the specified distance from the center point.
 */
export function filterJobsByLocation(
  jobs: JobDoc[],
  center: GeoPoint,
  distance: number
) {
  return jobs.filter((jobDoc) => {
    const jobData = jobDoc.data();
    const initialSite = find(jobData?.sites, { order: 0 });
    if (!initialSite) return false;
    const distanceToCenterMts =
      distanceBetween(
        [initialSite.lat, initialSite.lng],
        [center.latitude, center.longitude]
      ) * 1000;
    return distanceToCenterMts <= distance;
  });
}

/**
 * Determines if a job has been marked as stopped.
 *
 * This function checks the 'stopped' property of a job document. If the property exists and has a truthy value,
 * it indicates that the job has been stopped. The function returns a boolean value based on this check.
 *
 * @param {JobDoc} jobDoc - The job document to check for a stopped status. Assumes jobDoc is an object with a `get` method.
 * @returns {boolean} True if the job is marked as stopped, false otherwise.
 */
export function getIsJobStopped(jobDoc: JobDoc): boolean {
  return !!jobDoc.get('stopped');
}

/**
 * Retrieves truck data for a specific company associated with a job.
 *
 * This function searches through the 'companyTrucks' array in a job document for an entry matching the given company ID.
 * It returns the data object for that company, which includes the number of trucks accepted, assigned, and requested for the job.
 * If no matching company is found within the job document, the function returns undefined.
 *
 * @param {JobDoc} jobDoc - The job document containing an array of company trucks data. Assumes jobDoc is an object with a `get` method that allows accessing its properties.
 * @param {string} companyId - The unique identifier of the company for which truck data is being retrieved.
 * @returns {JobCompanyTruckData | undefined} The truck data for the specified company if found, or undefined if no match is found.
 */
export function getJobCompanyTrucksObj(
  jobDoc: JobDoc,
  companyId: string
): JobCompanyTruckData | undefined {
  return find(jobDoc.get('companyTrucks'), { companyId });
}

/**
 * Calculates the number of trucks accepted by the client for a given job.
 *
 * @param {JobDoc} jobDoc - The document of the job in question.
 * @returns {number} The count of trucks accepted by the client company.
 */
export function getJobTrucksAcceptedCountOfClient(jobDoc: JobDoc): number {
  const clientCompanyId = jobDoc.get('client')?.id;
  if (!clientCompanyId) return 0;
  const companyTrucksCount = getJobCompanyTrucksObj(jobDoc, clientCompanyId);
  return get(companyTrucksCount, 'trucksAcceptedCount', 0);
}

/**
 * Determines whether a job requires attention based on its dispatch status and truck counts.
 * @param {JobDoc} jobDoc - The document of the job to evaluate.
 * @returns {boolean} True if the job requires attention, false otherwise.
 */
export function getJobRequiresAttention(jobDoc: JobDoc): boolean {
  const totalTrucks = jobDoc.get('totalTrucks');
  const acceptedJobsByClientCount = getJobTrucksAcceptedCountOfClient(jobDoc);
  const jobStatus = jobDoc.get('overallStatus');
  return (
    jobStatus === 'INCOMPLETE' ||
    (!jobDoc.get('forceJobDispatch') &&
      !getIsJobStopped(jobDoc) &&
      (!acceptedJobsByClientCount ||
        !totalTrucks ||
        totalTrucks > acceptedJobsByClientCount))
  );
}

/**
 * Generates a string explaining why a job requires attention, focusing on the discrepancy between requested and accepted trucks.
 *
 * @param {JobDoc} jobDoc - The document of the job in question.
 * @returns {string} A message detailing the reason for the job requiring attention, or an empty string if the job does not require attention.
 */
export function getRequiresAttentionReasonString(jobDoc: JobDoc) {
  if (!getJobRequiresAttention(jobDoc)) {
    return '';
  }
  if (jobDoc.get('overallStatus') === 'INCOMPLETE') {
    return 'some details are still incomplete and it needs to be edited before proceeding.';
  }
  const totalTrucks = jobDoc.get('totalTrucks') || 0;
  const acceptedJobsByClientCount = getJobTrucksAcceptedCountOfClient(jobDoc);
  return (
    `${totalTrucks} ${totalTrucks > 1 ? 'trucks were' : 'truck was'} requested in total but ` +
    (!!acceptedJobsByClientCount
      ? `only ${acceptedJobsByClientCount} have accepted their assignment.`
      : 'no one has accepted yet.')
  );
}

/**
 * Identifies the current dispatch stage of a job.
 *
 * @param {JobDoc} jobDoc - The document of the job being evaluated.
 * @returns {JobDispatchStage} The dispatch stage of the job, which can be 'requiresAttention', 'dispatched', or 'completed'.
 */
export function getJobDispatchStage(jobDoc: JobDoc): JobDispatchStage {
  const doesJobRequiresAttention = getJobRequiresAttention(jobDoc);
  if (doesJobRequiresAttention) {
    return 'requiresAttention';
  }
  const overallStatus = jobDoc.get('overallStatus');
  const isJobDispatched =
    overallStatus === 'NEW' || overallStatus === 'IN_PROGRESS';
  if (isJobDispatched) return 'dispatched';
  return 'completed';
}

/**
 * Retrieves the names of all sites associated with a job.
 *
 * @param {JobDoc} jobDoc - The document of the job.
 * @returns {string[]} An array of site names for the job.
 */
export function getJobSitesNames(jobDoc: JobDoc): string[] {
  return map(jobDoc.get('sites'), 'name');
}

/**
 * Sorts an array of jobs by their overall status according to a predefined order.
 *
 * The function arranges jobs based on their lifecycle stages from new to completed or cancelled, with the ability to sort
 * in ascending or descending order. This sorting can help in displaying jobs in a user interface according to their progress or urgency.
 *
 * @param {JobDoc[]} jobs - An array of job documents to be sorted.
 * @param {'asc' | 'desc'} order - Specifies the sort order: 'asc' for ascending or 'desc' for descending.
 * @returns {JobDoc[]} A sorted array of job documents.
 */
export function sortJobsByStatus(
  jobs: JobDoc[],
  order: 'asc' | 'desc'
): JobDoc[] {
  let statusOrder: JobOverallStatus[] = [
    'INCOMPLETE',
    'NEW',
    'IN_PROGRESS',
    'IN_REVIEW',
    'APPROVED',
    'IN_DISPUTE',
    'CANCELLED',
    'DECLINED',
  ];
  if (order === 'desc') {
    statusOrder.reverse();
  }
  return orderBy(jobs, (jobDoc) =>
    statusOrder.indexOf(jobDoc.get('overallStatus') || 'NEW')
  );
}

/**
 * Retrieves information about trucks from a specific company associated with a job.
 * This function searches within a job document for truck data related to a given company ID and returns this data if found.
 *
 * @param {JobDoc} jobDoc - The document of the job containing truck information for various companies.
 * @param {string} companyId - The unique identifier of the company whose truck information is being requested.
 * @returns {JobCompanyTruckData} The truck data for the specified company if available, otherwise a default CompanyTruckData object with only total requested trucks count.
 */
export function getCompanyTrucksInfo(
  jobDoc: JobDoc,
  companyId: string
): JobCompanyTruckData {
  const companyTruckForCompany = find(jobDoc.get('companyTrucks'), [
    'companyId',
    companyId,
  ]);
  if (!companyTruckForCompany) {
    const defaultCompanyTruckData: JobCompanyTruckData = {
      companyId: companyId,
      trucksAcceptedCount: 0,
      trucksAssignedCount: 0,
      trucksRequestedCount: jobDoc.get('totalTrucks') || 0,
    };
    return defaultCompanyTruckData;
  }

  return companyTruckForCompany;
}

export function getJobInitialSiteRefMap(jobDoc: JobDoc) {
  const initialSite = find(jobDoc.get('sites'), { order: 0 });
  return initialSite;
}

/**
 * Asynchronously loads and returns a mapping of broker companies associated with a list of jobs.
 *
 * @param {JobDoc[]} jobs - An array of job documents from which broker company references will be extracted.
 *
 * @returns {Promise<Record<string, CompanyDoc>>} A promise that resolves to a record object.
 * Each key in the record object is a broker company ID, and the corresponding value is the full document
 * data for that broker company. This allows for efficient lookup of company details by their ID.
 */
export async function loadBrokerCompaniesFromJobs(
  jobs: JobDoc[]
): Promise<Record<string, CompanyDoc>> {
  const brokerCompaniesIds = compact(
    unionBy(map(jobs, (job) => job.get('brokerCompanyRef')?.id))
  );
  const brokerCompanyDocs = await Promise.all(
    brokerCompaniesIds.map((companyId) =>
      firestore.getDoc(Companies.doc(companyId))
    )
  );
  const brokerCompaniesById: Record<string, CompanyDoc> =
    brokerCompanyDocs.reduce(
      (brokerCompaniesById, companyDoc) => ({
        ...brokerCompaniesById,
        [companyDoc.id]: companyDoc,
      }),
      {}
    );

  return brokerCompaniesById;
}

/**
 * Retrieves the broker company's name for a given job document.
 * This function extracts the broker company ID from the job document and then looks up the corresponding
 * broker company document from an optional map of broker companies. If the broker company document is found,
 * it returns the company's business name. If not found, it returns an empty string. This allows for flexible
 * usage where the broker companies' data may or may not be pre-loaded.
 *
 * @param {JobDoc} jobDoc - The job document from which to retrieve the broker company's name.
 * @param {Object} [options] - Optional parameters to customize the function's behavior.
 * @param {BrokerCompaniesRecord} [options.brokerCompanies] - An optional map of broker company documents,
 *        indexed by company ID, which can be used to look up the broker company's name without additional
 *        database queries.
 *
 * @returns {string} The business name of the broker company associated with the given job document, or an
 *          empty string if the company's name cannot be found.
 */
export function getJobBrokerCompanyName(
  jobDoc: JobDoc,
  { brokerCompanies }: { brokerCompanies?: BrokerCompaniesRecord } = {}
): string {
  const brokerCompanyId = jobDoc.get('brokerCompanyRef')?.id || '';
  const brokerCompanyDoc = brokerCompanies?.[brokerCompanyId];
  return brokerCompanyDoc?.get('businessName') || '';
}

/**
 * Asynchronously determines whether any task associated with a given job has been invoiced.
 * This function fetches all tasks related to the specified job document and checks if at least one of those tasks
 * has an associated invoice. It's particularly useful for identifying jobs that have begun the invoicing process,
 * which may impact how those jobs are handled in project management, financial tracking, or reporting interfaces.
 *
 * @param {JobDoc} jobDoc - The job document for which to check for invoiced tasks.
 *
 * @returns {Promise<boolean>} A promise that resolves to `true` if at least one task associated with the job has been invoiced,
 *                             `false` otherwise.
 */
export async function getHasJobSomeInvoicedTask(jobDoc: JobDoc) {
  const jobTasks = await firestore
    .getDocs(tasksByJobQuery({ jobId: jobDoc.id }))
    .then(({ docs }) => docs);
  return some(jobTasks, (taskDoc) => getIsTaskInvoiced(taskDoc));
}

/**
 * Determines whether operators can be added to a specific job.
 *
 * @param {JobDoc} jobDoc - The job document object, containing details and status of the job.
 * @returns {Promise<boolean>} A promise that resolves to a boolean value indicating whether operators can be added to the job.
 *                             Returns `true` if operators can be added, and `false` otherwise.
 */
export async function getCanOperatorsBeAddedToJob(jobDoc: JobDoc) {
  const hasJobSomeInvoicedTask = await getHasJobSomeInvoicedTask(jobDoc);
  return (
    jobDoc.get('overallStatus') !== 'INCOMPLETE' && !hasJobSomeInvoicedTask
  );
}
